<template>
  <div>
    <b-form @submit.stop.prevent="formOnsubmit">
      <div class="row">
        <div class="col-md-4">
          <div style="height: 350px; width: 350px; position: relative">
            <img
              style="width: 100%; height: 100%; object-fit: contain"
              :src="[
                uploadedImageUrl == '' ? form.photo_name : uploadedImageUrl,
              ]"
            />
          </div>
        </div>
        <div class="col">
          <!-- Input Flow Type -->
          <b-form-group
            id="input-group-flow-type"
            label="Jenis Keuangan:"
            label-for="input-flow-type"
          >
            <b-form-select
              id="input-flow-type"
              v-model="form.flow_type"
              :options="flowTypes"
            ></b-form-select>
          </b-form-group>

          <b-form-group v-if="form.flow_type == 1"
            id="input-group-trigger-modal-citizen-4"
            label="Penduduk:"
            label-for="input-trigger-modal-citizen-4"
          >
            <b-form-input
              id="input-trigger-modal-citizen-4"
              v-model="display.citizen_name"
              placeholder="Pilih Penduduk"
              readonly
              @click="$bvModal.show('modal-citizen-4')"
            >
            </b-form-input>
            <small class="text-danger">{{ error.citizen_id }}</small>
          </b-form-group>

          <!-- Input Modal Trigger -->
          <b-form-group id="input-group-trigger-modal-expense-category">
            <label for="input-trigger-modal-expense-category"
              >Kategori Pengeluaran: <em class="text-muted">opsional</em></label
            >
            <b-form-input
              id="input-trigger-modal-expense-category"
              v-model="display.expense_category_name"
              placeholder="Pilih Kategori"
              :readonly="
                form.flow_type == 2 ? false : form.flow_type == 1 ? true : true
              "
              :class="
                form.flow_type == 2 ? 'disabled' : form.flow_type == 1 ? '' : ''
              "
              :disabled="
                form.flow_type == 2 ? true : form.flow_type == 1 ? false : false
              "
              @click="$bvModal.show('modal-expense-category')"
            >
            </b-form-input>
            <small class="text-danger">{{ error.expense_category_id }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-date"
            label="Tanggal:"
            label-for="input-date"
          >
            <b-input-group>
              <b-input-group-prepend>
                <b-form-datepicker
                  locale="id"
                  placeholder="Tanggal"
                  id="input-date"
                  v-model="form.date"
                  button-only
                  aria-controls="example-input"
                ></b-form-datepicker>
              </b-input-group-prepend>
              <b-form-input
                id="input-date"
                v-model="form.date"
                type="text"
                placeholder="YYYY-MM-DD"
                @input="formaterDate"
              ></b-form-input>
            </b-input-group>
            <small class="text-danger">{{ error.date }}</small>
          </b-form-group>

          <!-- Input Name -->
          <b-form-group
            id="input-group-name"
            label="Keterangan:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="form.name"
              placeholder="Keterangan"
            ></b-form-input>
            <small class="text-danger">{{ error.name }}</small>
          </b-form-group>

          <!-- Input Amount -->
          <b-form-group
            id="input-group-amount"
            label="Jumlah:"
            label-for="input-amount"
          >
            <b-form-input
              id="input-amount"
              v-model="formatedAmount"
              inputmode="numeric"
              placeholder="Jumlah"
            ></b-form-input>
            <small class="text-danger">{{ error.amount }}</small>
          </b-form-group>

          <!-- Input Note -->
          <b-form-group id="input-group-notes">
            <label for="input-notes"
              >Catatan: <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="input-notes"
              v-model="form.notes"
              placeholder="Catatan"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.notes }}</small>
          </b-form-group>

          <!-- Image -->
          <b-form-group id="input-group-image">
            <label for="input-image"
              >Gambar: <em class="text-muted">opsional</em></label
            >
            <b-form-file
              v-model="form.photo"
              placeholder="Upload Gambar"
              drop-placeholder="Drop file here..."
              @change="onFileUpload"
            ></b-form-file>
            <small class="text-danger">{{ error.photo }}</small>
          </b-form-group>

          <!-- Button -->
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            class="ml-2"
            variant="default"
            @click="$router.push('/expenses/list')"
          >
            Batal
          </b-button>
        </div>
      </div>
    </b-form>
    <ModalCitizen4 @chooseCitizen="chooseCitizen" source="expense"/>
    <ModalExpenseCategory @chosenItem="chosenItem" />
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalCitizen4 from "@/view/components/general/ModalCitizen4.vue";
import ModalExpenseCategory from "@/view/components/general/ModalExpenseCategory.vue";

export default {
  props: {
    form: Object,
    route: String,
  },

  components: {
    ModalCitizen4,
    ModalExpenseCategory,
  },

  data() {
    return {
      // Display Data
      display: {
        expense_category_name: "",
        citizen_name: "",
      },
      // Error
      error: {
        photo: "",
        date: "",
        name: "",
        amount: "",
        expense_category_id: "",
        notes: "",
      },
      // Options
      flowTypes: [
        { text: "Pengeluaran", value: 1 },
        { text: "Deposit", value: 2 },
      ],
      // Others
      imgLoaded: false,
      uploadedImageUrl: "",
    };
  },

  methods: {
    chooseCitizen(value) {
      this.form.citizen_id = value.id;
      this.display.citizen_name = value.name;
      this.$bvModal.hide("modal-citizen-4");
      console.log("hide");
    },
    chosenItem(value) {
      this.form.expense_category_id = value.id;
      this.display.expense_category_name = value.name;
      this.$bvModal.hide("modal-expense-category");
    },
    formaterDate(val) {
      if (val.length == 4) {
        this.form.date = this.form.date + "-";
      }
      if (val.length == 7) {
        this.form.date = this.form.date + "-";
      }
    },

    onImageLoad() {
      this.imgLoaded = true;
    },

    onFileUpload(evt) {
      this.uploadedImageUrl = URL.createObjectURL(evt.target.files[0]);
      this.form.photo = evt.target.files[0];
    },
    async formOnsubmit() {
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/expenses/list");
      }
    },
  },
  computed: {
    formatedAmount: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.amount = newValue.toLocaleString("id-ID");
        } else {
          this.form.amount = "";
        }
      },
      get() {
        return this.form.amount;
      },
    },
  },
  watch: {
    "form.expense_category_name": function (newVal, oldVal) {
      this.display.expense_category_name = newVal;
    },
    "form.citizen_name": function (newVal, oldVal) {
      this.display.citizen_name = newVal;
    },
  },
};
</script>

<style>
</style>